<template lang="pug">
.bg-blue-500.px-4.py-6.rounded-lg.shadow-xl
  .flex.flex-col
    .font-bold.text-3xl.mb-1.border-b.border-blue-400.pb-2.mb-2 Globals
    .flex.justify-between
      span Subscriptors:
      b {{ global.total_subscriptors }}
    .flex.justify-between
      span MRR:
      b {{ setPrice(global.mrr) }}
    .h-px.bg-blue-400.my-2
    .flex.justify-between
      span This Month:
      b {{ setPrice(global.payments_this_month) }}
    .flex.justify-between
      span Canceled:
      b {{ global.cancels_this_month || 0 }}
    .h-px.bg-blue-400.my-2
    .flex.justify-between
      span Last Month:
      b {{ setPrice(global.payments_last_month) }}
    .flex.justify-between
      span Canceled:
      b {{ global.cancels_last_month || 0 }}
</template>
<script>
export default {
  name: "sectionGlobalCard",
  props: {
    global: Object,
  },
  methods: {
    setPrice: (price) => {
      if (!price) return 0;
      price = price / 100;
      return new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency: "EUR",
      }).format(price);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>