<template lang="pug">
.container
  baseHeading Dashboard
  sectionTopCards.mb-16(
    :today="today",
    :yesterday="yesterday",
    :last7days="last7days"
  )
  .grid.grid-cols-3.gap-5
    .col-span-2
      sectionTable
    .col-span-1
      sectionGlobalCard(:global="global")
</template>

<script>
import AnalyticsRepository from "@/api/analytics.repository";

import sectionTopCards from "@/components/dashboard/sectionTopCards";
import sectionGlobalCard from "@/components/dashboard/sectionGlobalCard";
import sectionTable from "@/components/dashboard/sectionTable";
export default {
  name: "Home",
  components: {
    sectionTopCards,
    sectionGlobalCard,
    sectionTable,
  },
  data: () => ({
    today: {},
    yesterday: {},
    last7days: {},
    global: {},
  }),
  created() {
    AnalyticsRepository.basicAnalytics().then(({ data }) => {
      if (data.today) this.today = data.today;
      if (data.yesterday) this.yesterday = data.yesterday;
      if (data.last7days) this.last7days = data.last7days;
    });
    AnalyticsRepository.globalAnalytics().then(({ data }) => {
      this.global = data;
    });
  },
  methods: {
    setPrice: (price) => {
      if (!price) return 0;
      price = price / 100;
      return new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency: "EUR",
      }).format(price);
    },
  },
};
</script>
