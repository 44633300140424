<template lang="pug">
.grid.grid-cols-4.gap-4.mb-4
  baseButton.bg-green-500(
    @click="countryRangeSelected = 'today'",
    :class="{ 'opacity-50': countryRangeSelected == 'today' }"
  ) Today
  baseButton.bg-yellow-500(
    @click="countryRangeSelected = 'yesterday'",
    :class="{ 'opacity-50': countryRangeSelected == 'yesterday' }"
  ) Yesterday
  baseButton.bg-indigo-500(
    @click="countryRangeSelected = 'last7days'",
    :class="{ 'opacity-50': countryRangeSelected == 'last7days' }"
  ) Last 7 Days
  baseButton.bg-blue-500(
    @click="countryRangeSelected = 'last30days'",
    :class="{ 'opacity-50': countryRangeSelected == 'last30days' }"
  ) Last 30 Days

dataTable(:rows="rows", :headings="headings", :options="options")
</template>
<script>
import AnalyticsRepository from "@/api/analytics.repository";
import dataTable from "@/components/dataTables/dataTable";

export default {
  name: "sectionTable",
  components: {
    dataTable,
  },
  data: () => ({
    rows: [],
    headings: ["Country", "Users", "Trials", "Subscriptions", "Payouts"],
    countryRangeSelected: "today",
    options: {
      perPage: 5,
      fixedHeight: false,
    },
  }),
  watch: {
    countryRangeSelected: {
      handler() {
        this.getCountryAnalytics();
      },
      immediate: true,
    },
  },
  methods: {
    setPrice: (price) => {
      if (!price) return 0;
      price = price / 100;
      return new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency: "EUR",
      }).format(price);
    },
    getCountryAnalytics() {
      AnalyticsRepository.countryAnalytics(this.countryRangeSelected).then(
        ({ data }) => {
          this.rows = Object.values(data).map((obj) => {
            return {
              c: obj.country,
              users: `${obj.users}`,
              payouts_trials: `${obj.payouts_trials}`,
              payouts_subscriptions: `${obj.payouts_subscriptions}`,
              cash: `${this.setPrice(obj.cash)}`,
            };
          });
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
</style>