<template lang="pug">
.grid.grid-cols-3.gap-5
  TopCard.green(:data="today", title="Today")
  TopCard.yellow(:data="yesterday", title="Yesterday")
  TopCard.indigo(:data="last7days", title="Last 7 Days")
</template>
<script>
import TopCard from "./topCard";
export default {
  name: "sectionTopCards",
  components: {
    TopCard,
  },
  props: {
    today: Object,
    yesterday: Object,
    last7days: Object,
  },
};
</script>
<style lang="scss" scoped>
</style>