<template lang="pug">
.default-card
  .flex.flex-col
    .font-bold.text-3xl.mb-1.border-b.pb-2.mb-2.border_title {{ title }}
    .flex.justify-between
      span Users:
      b {{ data.users || 0 }}
    .flex.justify-between
      span Trials:
      b {{ data.total_payments_trial || 0 }}
    .flex.justify-between
      span P. Trials:
      b {{ setPrice(data.payment_trials) }}
    .flex.justify-between
      span Subscriptions:
      b {{ data.total_payments_subscription || 0 }}
    .flex.justify-between
      span P. Subscriptions:
      b {{ setPrice(data.payment_subscription) }}
    .flex.justify-between
      span Canceled:
      b {{ data.cancelations || 0 }}
</template>
<script>
export default {
  name: "topCard",
  props: {
    data: Object,
    title: String,
  },
  methods: {
    setPrice: (price) => {
      if (!price) return 0;
      price = price / 100;
      return new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency: "EUR",
      }).format(price);
    },
  },
};
</script>
<style lang="scss" scoped>
.green {
  @apply bg-green-600;
  .border_title {
    @apply border-green-500;
  }
}

.yellow {
  @apply bg-yellow-600;
  .border_title {
    @apply border-yellow-500;
  }
}

.indigo {
  @apply bg-indigo-600;
  .border_title {
    @apply border-indigo-500;
  }
}
</style>